@import "_sizes.less";
@import "../../abstracts/_colors.less";
//@import "_animations.less"; // Desabilitado, pois já é importado no _exato-web.less.
@import "../../abstracts/_screen-size.less";

@fullHeight: 70px;
@compactHeight: 65px;

@media print {
  .box-menus,
  .box-user,
  .separator-line,
  .icon,
  .super-search,
  .logo-exato-shield {
    display: none !important;
  }

  .logo-exato {
    display: block !important;
  }

  .nav-float {
    box-shadow: none !important;
  }
}

// .nav-item {
//   position: relative;
//   color: @ColorPriGrey;

//   @media (min-width: @screenLarge) {
//     display: flex;
//     align-items: center;
//   }
// }

// .nav-item-corporate {
//   order: 1;
// }
// .nav-item-resources {
//   order: 2;
// }
// .nav-item-login {
//   order: 3;
// }

// @media (max-width: @screenMedium) {
//   .nav-item-corporate {
//     order: 2;
//   }

//   .nav-item-resources {
//     order: 3;
//   }

//   .nav-item-login {
//     order: 4;
//   }
// }

/* novo menu mobile */

// evitar que o conteúdo "salte" no scroll
.box-container-navbar {
  height: 60px;

  @media (min-width: @screenLarge) {
    height: 70px;
  }
}

.container-navbar {
  background-color: #fff;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  transition: all 0.3s;

  @media (min-width: @screenLarge) {
    //ficar abaixo dos modais
    z-index: 9;
  }
}
.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.063rem 0;
  //padding: 0;
  margin-right: auto;
  margin-left: auto;

  a {
    text-decoration: none;
  }

  @media (min-width: @screenLarge) {
    position: relative;
    padding: 0;
    //padding: 0.7815rem 0;
  }
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;

  @media (min-width: @screenLarge) {
    padding-top: 0;
  }
}

#navbarMenuPrincipal {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 0;
  max-height: calc(100vh - 60px);
  background-color: @ColorSecGrey03;
  z-index: 1000;

  //transition: all 0.3s ease-in-out;
  transition: all 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);

  overflow: auto;

  @media (min-width: @screenLarge) {
    position: relative;
    top: 0;
    height: auto;
    background-color: transparent;

    overflow: initial;
  }

  &.show {
    height: 100vh;
    @media (min-width: @screenLarge) {
      height: auto;
    }
  }
}

@media (min-width: @screenLarge) {
  .navbar-expand-lg {
    flex-flow: row nowrap;

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
  }
}

.navbar-toggler {
  margin-left: auto;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent !important;
  color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0.25rem;

  &:focus {
    outline: none;
  }

  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }

  .middle-bar {
    opacity: 0;
  }

  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }

  &.collapsed {
    .top-bar {
      transform: rotate(0);
    }

    .middle-bar {
      opacity: 0.75;
    }

    .bottom-bar {
      transform: rotate(0);
    }
  }
}

.icon-bar {
  width: 22px;
  height: 2px;
  background-color: @ColorPriGrey;
  display: block;
  transition: all 0.2s;
  margin-top: 4px;
  opacity: 0.75;
}

@media (min-width: @screenLarge) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.nav-float-mode {
  position: fixed;
  top: 0px;
  z-index: 1030;
  // background-color: #fff !important;
  box-shadow: 0px 1px 14px 0px rgba(50, 50, 50, 0.2);

  .icon-bar {
    background-color: @ColorPriGrey;
  }

  //TODO - rever esse trecho
  @media (min-width: @screenLarge) {
    //ficar abaixo dos modais
    z-index: 9;

    // .nav-item {
    //   color: @ColorPriGrey;
    // }
    // .nav-item-separator {
    //   border-left: 1px solid @ColorSecGrey05;
    // }
    /deep/ .brand-text {
      display: none;
    }
    /deep/ .brand {
      width: 244px;
      height: 33px;
    }
  }
  // .nav-link:hover {
  //   color: @ColorPriYellow;
  // }
}

// // TODO classes nav-link poderiam ficar no nav-link-horizontal
// .nav-link.menu-selected {
//   color: @ColorPriYellow;

//   .dropdown-indicator {
//     transform: rotate(180deg);
//   }

//   &::before {
//     content: "";
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 2px;
//     background-color: @ColorPriYellow;
//   }

//   .dropdown-caret {
//     &:before {
//       border-color: @ColorPriYellow;
//     }
//   }
// }

.dropdown {
  position: relative;
}

.dropdown-label {
  margin-right: 0.5rem;
}

.dropdown-icon {
  margin-right: 0.625rem;
}

.dropdown-indicator {
  margin-left: auto;
}

.dropdown-label,
.dropdown-indicator {
  transition: 0.3s all;
}

/* end novo mobile */

// Classes bradesco
.nav-positions-container {
  background-color: #fcfcfc;
  border-top: 1px solid @ColorSecGrey03;
}

.nav-item-position {
  margin: 0 2rem;

  &:first-of-type {
    margin-left: 0;
  }
}

.nav-positions {
  display: flex;
}

.nav-link-position {
  display: block;
  padding: 0.65rem 0rem;
  color: @ColorSecGrey47;
  font-size: 13px;
  text-decoration: none;

  &:hover {
    color: @yellow-600 !important;
  }
}

.nav-link-position.active {
  color: @ColorPriTurquoise;
}
.nav-positions {
  .icon-micro {
    width: 13px;
    height: 13px;
  }
}

.navbar-active-directory {
  height: 70px;
}

.dropdown-menu {
  @media (min-width: @screenLarge) {
    position: absolute;
    top: 100%;
    right: 0;
    // right: 2rem;
    z-index: 1000;
    min-width: 10rem;
    padding: 1.5rem 2rem;
    margin: 0;
    font-size: 1rem;
    text-align: left;
    background-color: #f4f4f4;
    background-clip: padding-box;
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  }
}

.dropdown-large {
  position: static !important;
}

.dropdown-menu-large {
  @media (min-width: @screenLarge) {
    width: 930px;
    max-width: 930px;
    padding: 2rem 0;
    font-size: 0.875rem;
  }

  @media (min-width: @screenExtraLarge) {
    width: 1110px;
    max-width: 1110px;
  }
}

.dropdown-group {
  // padding: 1rem 0 .5rem 0;
  padding: 1rem 0;
  // border-bottom: 1px solid @light100;

  @media (min-width: @screenLarge) {
    // border-bottom:0;
    padding: 0 2.5rem;

    &:not(:last-child) {
      border-right: 1px solid @light100;
    }
  }
}

.dropdown-group-text {
  margin-bottom: 0.5rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  border: 0;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
  .icon {
    top: 0.2em;
    margin-right: 0.875rem;
    transition: 0.3s all;
  }

  @media (min-width: @screenLarge) {
    padding: 0.5rem 0rem;
    font-size: 0.875rem;
  }
}

.dropdown-group {
  .dropdown-item:last-child {
    padding-bottom: 0;
  }
}

.dropdown-group-item {
  width: 100%;
  padding: 0.5rem 0;
  font-weight: 500;
  text-transform: uppercase;

  &:first-of-type {
    padding-top: 0;
  }
}

.dropdown-item,
a.dropdown-group-item {
  display: flex;
  color: @ColorPriGrey;

  @media (min-width: @screenLarge) {
    &:focus,
    &:hover {
      color: @yellow-600;

      .icon {
        color: @yellow-600;
      }
    }
  }

}
.dropdown-group-indicator {
  margin-left: auto;
  transform: rotate(270deg);
}

.dropdown-item-large {
  padding-left: 0;
  padding-right: 0;

  .icon {
    // top: 0.2em;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 0.375rem;
    color: @turquesa-500;

    @media (min-width: @screenLarge) {
      width: 16px;
      height: 16px;
    }
  }
}

.dropdown-group-item.router-link-exact-active,
.dropdown-item.router-link-exact-active {
  color: @yellow-600;
  cursor: default;
  .icon {
    color: @yellow-600;
    cursor: default;
  }
}

// .icon-small-2 {
//   width: 16px;
//   height: 16px;

//   @media (min-width: @screenLarge) {
//     width: 14px;
//     height: 14px;
//   }

//   // &.icon-with-text {
//   //   top: 0.3em;
//   //   margin-right: 0.25rem;
//   // }
// }

.icon-arrow {
  width: 12px;
  height: 12px;

  @media (min-width: @screenLarge) {
    width: 8px;
    height: 8px;
  }
}

.flag {
  display: block;
  width: 24px;
  height: 24px;
}

// .dropdown-menu {
//   .flag {
//     width: 16px;
//     height: 16px;
//   }
// }

.user-name-container {
  margin: 0 12px 0 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  user-select: none;
}

.dropdown-item-container {
  display: flex;
  align-items: center;
}

.box-user {
  padding-left: 0.5rem;
  color: @ColorPriGrey;
  cursor: pointer;
  transition: all 0s;

  &:hover,
  &:focus {
    color: @yellow-600;

    .user-name,
    .icon-micro {
      color: @yellow-600 !important;
    }
  }

  &.menu-selected {
    color: @yellow-600;

    .icon-arrow {
      transform: rotate(180deg);
    }
  }

  > * {
    display: inline-block;
    vertical-align: middle;
  }

  .user-name-company {
    font-weight: 500;
  }

  .icon-with-text-2 {
    margin-right: 0.25rem;
  }

  .icon-arrow {
    width: 8px;
    height: 8px;
  }


  .user-name,
  .user-name-company,
  .icon-with-text-2,
  .icon-arrow {
    transition: all 0.3s;
  }
}

.user-image-box {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: inherit;
    height: inherit;
  }
}

@media (max-width: @screenMedium) {
  .md-separator-top {
    border-top: 1px solid #e3e5e5;
  }
}
