@import "../abstracts/_colors";
@import "../abstracts/_screen-size";

// Extraído do exato-web/_global.less

.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-big {
  width: 34px;
  height: 34px;
}

.icon-medium {
  width: 24px;
  height: 24px;
}

.icon-small {
  width: 16px;
  height: 16px;
}
.icon-small-2 {
  width: 16px;
  height: 16px;

  @media (min-width: @screenLarge) {
    width: 14px;
    height: 14px;
  }
}

.icon-extrasmall {
  width: 14px;
  height: 14px;
}

.icon-micro {
  width: 12px;
  height: 12px;
}

.icon-micro-2 {
  width: 8px;
  height: 8px;
}

.icon-with-text,
.icon-with-text-2 {
  position: relative;
  margin-right: .5rem;
}

.icon-with-text {
  top: .2em;
}

.icon-with-text-2 {
  top: .1em;
}

.icon-with-text-3 {
  top: 3.6px;
}

.icon-ellipse {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 8px 2px 3px;
  border-radius: 50%;
  background-color: @ColorPriGrey;
}

.icon-eye {
  cursor: pointer;
}
