.alert-warning {
  a {
    text-decoration: underline;
    color: inherit;
    
    &:hover {
      text-decoration: none;
    }
  }
}
