/*
    Assets import
    =============
*/

// Está afetando outras partes do site
//@import "bootstrap/bootstrap-reboot.less"; // Desabilitado, pois aqui na documentação da API já tem Bootstrap com Reboot.
/*  */

// Avaliar retirada deste reset
//@import "reset.less"; // Desabilitado, pois aqui na documentação da API já tem Bootstrap com Reboot.
/*  */

@import "../../abstracts/_colors.less";
@import "_fonts.less";
//@import "_animations.less"; // Desabilitado, pois já é importado no _exato-web.less.
@import "../../abstracts/_screen-size.less";

//@import "vendor-overrides/hooper.less"; // Desabilitado, pois não existem sliders aqui.

//@import "components/hooper-pagination-variant-1.less"; // Desabilitado, pois não existem sliders aqui.


@BodyText: 1rem;
@BodyText2: 0.875rem; //14px
@BodyText3: 0.75rem; //12px
@BodyText18: 1.1250rem;


svg:not(:root) {
  vertical-align: initial; //BUG BOOTSTRAP 4.1.2
}

/*
    Default style for elements
    ==========================
*/

// Desabilitado, pois aqui na documentação da API já tem Bootstrap com Reboot.
//html {
//  height: 100%;
//  //conflito com o scrollTo que esta na rota
//  // scroll-behavior: smooth;
//}

// Movido para o "_exato-web.less", pois isso nunca vai funcionar devido ao
// formato que o arquivo está sendo importado aqui (apenas funciona dentro de
// um elemento com o seletor ".exato-web").
body {
  font-family: 'Saira';
  font-size: 1rem;
  line-height: 1.5;
  color: @ColorPriGrey;
  background-color: @ColorSecGrey03;
  height: inherit;
}

.corporate {
  background: #28464F;
}

h1 {
  //font-family: @FontH1;
  font-size: 60pt;
}

h2 {
  /*     font-family: @FontH2;
  font-size: 45pt;
  line-height: 1.44; */

  //font-weight: 300;
  line-height: 1.2;
  font-size: 36px;


}

h3 {

  font-family: saira;
  font-size: 24px;
  margin-bottom: .5rem;

  //não deveria ficar aqui
  // text-align: center;


  /*     font-family: @FontH3;
  line-height: 1.2;                    
  font-size: 36px;     */

}

// ATENÇÃO - Replicado na landing - Checar se é possível tirar da landing
h4 {
  font-size: 18px;
  margin-bottom: .5rem;

  // color: #5f5c5c;


  /*  font-family: @FontH4; */

  /* font-size: 36pt;
  line-height: 1.33; */
}

h5 {
  //font-family: @FontH5;
  font-size: 28pt;
  line-height: 1.29;
}

h6 {
  //font-family: @FontH6;
  font-size: 22pt;
  line-height: 1.36;
}

a {
  /* font-family: @FontLinks; */
  font-family: 'Saira';
  color: @ColorPriTurquoise;
  cursor: pointer;
  text-decoration: none;

  transition: .3s all;

  &:hover {
    color: @yellow-600;
    text-decoration: none;
  }
}

a[disabled=''],
a[disabled='true'],
a[disabled='True'],
a[disabled='disabled'] {
  pointer-events: none;
}

b,
strong {
  font-weight: 600;
}

sup {
  font-size: 75%;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

/*  */
.title-bar {
  display: inline-block;
  width: 80px;
  height: 5px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  background-color: @ColorPriYellow;

  @media (min-width: @screenLarge) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

// Copiado nos estilos Less em "components/_icon.less".
.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-big {
  width: 34px;
  height: 34px;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-medium {
  width: 24px;
  height: 24px;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-small {
  width: 16px;
  height: 16px;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-small-2 {
  width: 16px;
  height: 16px;

  @media (min-width: @screenLarge) {
    width: 14px;
    height: 14px;
  }
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-extrasmall {
  width: 14px;
  height: 14px;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-micro {
  width: 12px;
  height: 12px;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-micro-2 {
  width: 8px;
  height: 8px;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-with-text,
.icon-with-text-2 {
  position: relative;
  margin-right: .5rem;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-with-text {
  top: .2em;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-with-text-2 {
  top: .1em;
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-with-text-3 {
  top: 3.6px;
}






/*
    v-Tooltip
    =========
*/

.tooltip {
  display: block !important;
  z-index: 10000;

  &.uid {
    .tooltip-inner {
      max-width: 290px;
      text-transform: uppercase;
      word-wrap: break-word;
    }
  }

  .tooltip-inner {
    //background-color: @ColorPriTurquoise;
    background-color: @ColorSecGrey84;
    color: @ColorSecGrey03;
    border-radius: 8px;
    padding: 14px;
    font-size: 14px;
    max-width: 250px;
    text-align: center;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: @ColorSecGrey84;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: 50% - 5px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: 50% - 5px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: 50% - 5px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: 50% - 5px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {

    .popover-inner {
      background-color: @ColorSecGrey84;
      color: @ColorSecGrey03;
      border-radius: 4px;
      padding: 12px;
      font-size: 14px;
      max-width: 250px;
      text-align: center;
    }

    .popover-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: @ColorSecGrey84;
      z-index: 1;


      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: 50% - 5px;
      margin-top: 0;
      margin-bottom: 0;

    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

.tolltip-error .tooltip-inner {
  background-color: @ColorError  !important;
}

.tolltip-error .tooltip-arrow {
  border-color: @ColorError  !important;
}

/*
    Global classes
    ==========================
*/

.error-message {
  margin-top: .25rem;
  color: @ColorError;
  font-size: 12.5px;
}

.text-success {
  color: @ColorOk;
}

.text-danger {
  color: @ColorError;
}


@media print {
  .no-print {
    display: none !important;
  }
}

.small-description {
  font-size: 0.875rem; //14px
  font-weight: 300;

}

.limited-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nowrap {
  white-space: nowrap;
}
.word-break {
  word-break: break-all;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center !important;
}


.text-center-md {
  @media (max-width: @screenMedium) {
    text-align: center !important;
  }
}



/*
    Assustes carousel - CRIAR ARQUIVO ESPECÍFICO
    ==========================
*/

.VueCarousel-wrapper {

  padding: .75rem 0 2rem 0;
  /*  overflow: auto !important;
  overflow-x: hidden !important;
  */
}

.VueCarousel-dot-container {
  margin-top: 0rem !important;

}

.VueCarousel-dot {
  width: 8px !important;
  height: 8px !important;
  margin-top: 0 !important;

  &:focus {
    outline: none !important;
  }
}

.VueCarousel-navigation-button {
  top: 45% !important;
  color: red !important;
  height: 32px !important;
  width: 32px !important;
  border-radius: 50% !important;
  background-color: rgba(180, 190, 185, 0.2) !important;
  transition: all .3s;

  &:focus {
    outline: none !important;
  }

  &:hover:not(.VueCarousel-navigation--disabled) {
    background-color: rgba(180, 190, 185, 0.5) !important
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 22px;
    height: 19px;
    transform: translate(-50%, -50%);
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(/images/img-arrow.svg);

    opacity: .7;
  }
}

.VueCarousel-navigation-prev {
  transform: translateY(-50%) translateX(-150%) rotate(180deg) !important;
  //left: 5px !important;
  left: 38px !important;
}

.VueCarousel-navigation-next {
  right: 32px !important;
}

.VueCarousel-navigation--disabled {
  opacity: 0.3 !important;
}



.animated-pricing-header {
  animation-delay: 200ms;
  opacity: 0;
  animation: fade-in-up 1s ease-in-out;
  animation-fill-mode: forwards;
  transition: all .3s;
}


@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 60px, 0);
  }

  30% {
    transform: translate3d(0, -30px, 0);
  }

  60% {
    //opacity: 1;
    transform: translate3d(0, 10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.bg-white {
  background-color: @ColorPriWhite;
}

.body-text-default {
  font-size: 1rem !important;
}

.body-text-1 {
  font-size: 0.875rem !important; // 14 px
}

.body-text-2 {
  font-size: 0.75rem; // 12 px
}

.body-text-18 {
  font-size: @BodyText18;
}

.section-group {
  margin-bottom: 4rem;
}

.action-disabled {
  color: #D5D5D5 !important;
  cursor: default;
}

.action-page {
  margin-left: auto;

  button:last-of-type {
    padding-right: 0;
  }
}

/* vdp-datepicker  */

.vdp-datepicker input {
  border: 0;
  border-bottom: 1px solid fuchsia;
  font-size: 14px;
  // font-family: @FontInputs;
  text-decoration: none;
  border-color: transparent;
  border-bottom: 1px solid #b4beb9;
  outline-style: none;
  padding: 3px 0px 3px 0px;
  background-color: transparent;
  width: 80px;
  // color: @ColorSecBlack;

  font-family: saira;
  color: @ColorPriTurquoise;

}

.vdp-datepicker__calendar {
  border-radius: 5px;
  box-shadow: 6px 6px 29px -16px rgba(0, 0, 0, 0.8);
  padding: 8px;
}

.vdp-datepicker__calendar .cell {
  border-radius: 5px;
  transition: all .3s;
}

.vdp-datepicker__calendar .cell.selected {
  background: @ColorPriYellow  !important;
  color: white;
}


.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border: 1px solid @ColorPriYellow  !important;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid @ColorPriYellow  !important;
}

.vdp-datepicker__calendar header .prev,
.vdp-datepicker__calendar header .next,
.vdp-datepicker__calendar header .up {
  border-radius: 5px;
  transition: all .3s;
}

.vdp-datepicker__calendar .cell.day-header {
  font-size: 80% !important;
}

.vdp-datepicker__calendar header .prev:after {

  margin-left: -1px !important;
  margin-top: -3px;

  border: 0 !important;
  border-style: solid !important;
  border-width: 0 1px 1px 0 !important;
  border-radius: 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(135deg) !important;
}

.vdp-datepicker__calendar header .next:after {

  margin-left: -2px !important;
  margin-top: -3px;

  border: 0 !important;
  border-style: solid !important;
  border-width: 0 1px 1px 0 !important;
  border-radius: 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(315deg) !important;
}

.negative-currency {
  color: @ColorNegative  !important;
}

.low-balance-currency {
  color: @ColorPriYellow  !important;
}

.text-color-red {
  color: @ColorError  !important;
}

.text-ColorSecGrey24 {
  color: @ColorSecGrey24;
}

.text-ColorSecGrey47 {
  color: @ColorSecGrey47  !important;
}

.text-ColorPriWhite {
  color: @ColorPriWhite  !important;
}

.text-ColorPriTurquoise {
  color: @ColorPriTurquoise  !important;
}

.text-ColorPriYellow {
  color: @ColorPriYellow  !important;
}

.text-orange-500 {
  color: @orange500  !important;
}

.text-turquesa-800 {
  color: @turquesa-800  !important;
}

.text-warning-1200 {
  color: @warning-1200  !important;
}

.text-white {
  color: @ColorPriWhite  !important;
}


.text-green-500 {
  color: darken(@greenExato, 10%);
}

.text-red-500 {
  color: darken(@redExato, 10%) !important;
}

.text-gray-300 {
  color: @gray300 !important;
}

.text-muted {
  color: lighten(@ColorSecGrey47, 15%);
}


.bd-ColorSecGrey47 {
  border-color: @ColorSecGrey47  !important;
}

.bd-ColorSecGrey74 {
  border-color: @ColorSecGrey74  !important;
}

.bg-ColorSecGrey14 {
  background-color: @ColorSecGrey14  !important;
}

.bg-ColorSecGrey05 {
  background-color: @ColorSecGrey05  !important;
}

.bg-ColorSecGrey03 {
  background-color: @ColorSecGrey03  !important;
}

.bg-ColorSecGrey02 {
  background-color: @ColorSecGrey02  !important;
}

.bg-turquesa-100 {
  background-color: @turquesa-100  !important;
}
.bg-turquesa-400 {
  background-color: @turquesa-400  !important;
}

.bg-turquesa-500 {
  background-color: @turquesa-500  !important;
}

.bg-danger-100 {
  background-color: @danger-100  !important;
}

.bg-green-100 {
  background-color: @green-100  !important;
}

.bg-color-white {
  background-color: #FFF !important;
}

.bg-ColorPriYellow {
  background-color: @ColorPriYellow  !important;
}
.bg-yellow-100 {
  background-color: @yellow-100  !important;
}
.bg-gray-300 {
  background-color: @gray300 !important;
}


button:focus {
  outline: none;
}

a.disabled {
  cursor: auto;
  color: #5f5c5c;
  text-decoration: none;

  &:hover {
    opacity: 1 !important;
  }
}

// Copiado nos estilos Less em "components/_icon.less".
.icon-ellipse {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 8px 2px 3px;
  border-radius: 50%;
  background-color: @ColorPriGrey;
}

.input-icon-popover {
  vertical-align: middle !important;
}

.input-icon .v-popover div {
  position: absolute;
  z-index: 8;
  right: 0;
  bottom: 7px;
}

.form-text {
  margin-top: .25rem;
;
  font-size: 12.5px;
}


@media print {
  body {
    background-color: #FFF;
  }
}

.text-area-div {
  padding: 5px;
  border: 1px solid @ColorSecGrey63;
  border-radius: 4px;
}

.text-area-div:focus-within {
  border: 1px solid darken(@ColorPriYellow, 10%);
}

textarea {
  resize: none;
  width: 100%;
  padding: 0;
  font-weight: 300;
  outline: none;
  border: none;

  &:focus:not([readonly]) {
    outline: none;
    outline-offset: 0px;
  }
}


// Copiado nos estilos Less em "components/_icon.less".
.icon-eye {
  cursor: pointer;
}

.text-weight-medium {
  font-weight: 500;
}

.text-weight-bold {
  font-weight: 600;
}

.text-weight-light {
  font-weight: 300;
}

.new-line {
  white-space: pre-line;
}

.click-ignore {
  pointer-events: none;
}

.text-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}



.mb-0 {
  margin-bottom: 0 !important;
}

.mb-0-25 {
  margin-bottom: .25rem !important;
}

.mb-0-5 {
  margin-bottom: .5rem !important;
}

.mb-0-75 {
  margin-bottom: .75rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-1-25 {
  margin-bottom: 1.25rem !important;
}

.mb-1-5 {
  margin-bottom: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-2-5 {
  margin-bottom: 2.5rem !important;
}


.mb-sm-2 {
  margin-bottom: 2rem !important;

  @media (min-width: @screenMedium) {
    margin-bottom: 0 !important;
  }
}

.mb-md-1 {
  @media (max-width: @screenMedium) {
    margin-bottom: 1rem !important;
  }
}
.mb-md-2 {
  @media (max-width: @screenMedium) {
    margin-bottom: 2rem !important;
  }
}
.mb-md-0 {
  @media (max-width: @screenMedium) {
    margin-bottom: 0 !important;
  }
}

.mb-sm-0 {
  @media (max-width: @screenSmall) {
    margin-bottom: 0 !important;
  }
}
.mb-sm-1 {

  @media (max-width: @screenSmall) {
    margin-bottom: 1rem !important;
  }

  /* margin-bottom: 1rem !important;

  @media (min-width: @screenMedium) {
      margin-bottom: 0 !important;
  } */
}

.m-auto{
  margin:auto;
}
.m-left-auto{
  margin-left:auto;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-1-5 {
  margin-right: 1.5rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mr-md-1-5 {
  @media (min-width: @screenMedium) {
    margin-right: 1.5rem !important;
  }
}

.mr-md-2 {
  margin-right: 0 !important;

  @media (min-width: @screenMedium) {
    margin-right: 2rem !important;
  }
}

.mt-1 {
  margin-top: 1rem !important;
}
.mt-0-5 {
  margin-top: .5rem !important;
}

.mt-md-0-25 {
  @media (max-width: @screenMedium) {
    margin-top: 0.25rem !important;
  }
}
.mt-md-0-5 {
  @media (max-width: @screenMedium) {
    margin-top: 0.5rem !important;
  }
}

.mt-1-5 {
  margin-top: 1.5rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-md-1 {
  @media (min-width: @screenMedium) {
    margin-left: 1rem !important;
  }
}

.ml-1-5 {
  margin-left: 1.5rem !important;
}

.ml-0-25 {
  margin-left: .25rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pt-md-1 {
  @media (max-width: @screenMedium) {
    padding-top: 1rem !important;
  }
}

.pt-md-1-25 {
  @media (max-width: @screenMedium) {
    padding-top: 1.25rem !important;
  }
}



.pb-0 {
  padding-bottom: 0 !important;
}

.pb-0-25 {
  padding-bottom: .25rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.pb-lg-8 {
  @media (min-width: @screenLarge) {
    padding-bottom: 8rem !important;
  }
}

.pb-md-1 {
  @media (max-width: @screenMedium) {
    padding-bottom: 1rem !important;
  }
}

.pb-md-1-25 {
  @media (max-width: @screenMedium) {
    padding-bottom: 1.25rem !important;
  }
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 1rem !important;
}


.h-100 {
  height: 100%;
}

.h-100-screen-large {
  @media (min-width: @screenLarge) {
    height: 100%;
  }
}

.h-auto {
  height: auto !important;
}

.h-min-auto {
  min-height: auto !important;
}

// evitar o layout shift na seção hero
.hero-h-100-vh {
  min-height: calc(100vh - 60px);
}

.img-fluid {
  max-width: 100%;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}


.cursor-action {
  cursor: pointer;
}

.cursor-no-action {
  cursor: auto !important;
}

.arrow-left {
  transform: rotate(180deg)
}

.container-slider {
  @media (max-width: @screenSmall) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.scroll-margin-top {
  scroll-margin-top: 60px;
  scroll-snap-margin-top: 60px;

  @media (min-width: @screenLarge) {
    scroll-margin-top: 70px;
    scroll-snap-margin-top: 70px;
  }
}

.button-box {

  @media (max-width: @screenMedium) {
    text-align: center !important;
  }
  /* @media (max-width: @screenSmall) {
      text-align: center !important;
  } */
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

// Alterando posição da badge do google recaptcha
.grecaptcha-badge {
  left: 4px !important;
  z-index: 999999;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}


@media (min-width: @screenLarge) {
  .scrollbar {

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f0f2f8;
      border-radius: 120px;

      &:hover {
        background-color: darken(#f0f2f8, 2%);
      }
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dee2e6;
      border-radius: 120px;

      &:hover {
        background-color: darken(#dee2e6, 5%);
      }
    }
  }

  .scrollbar-horizontal {
    padding-bottom: .25rem;

    &::-webkit-scrollbar {
      height: 12px;
    }
  }

  .scrollbar-vertical {
    &::-webkit-scrollbar {
      width: 12px;
    }
  }
}
