.swagger-ui .opblock .opblock-summary-path__deprecated {
  text-decoration: none;
}

/*        .opblock-summary-method {
            border-color: black !important;
            background: black !important;
        }*/

.opblock-title_normal {
  visibility: hidden;
}

.opblock-title_normal:before {
  visibility: visible;
  content: "Aviso: Serviço indisponível";
}

.swagger-ui .wrapper {
  padding: 0;
}

.swagger-ui .col-12 {
  padding: 0;
}

.swagger-ui .topbar {
  display: none;
  padding: 16.8px 0;
  background-color: #f6f4ec;
}

.swagger-ui .topbar-wrapper img {
  /*content: url('https://fakeimg.pl/250x100/ff0000/');*/
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' class='brand fade-in' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1153.86 135.91' %3e%3cg class='brand-text'%3e%3cpath d='M422.93,90.74l6.11,20H407.51l-6.11-20Z' fill='%23fbc257' class='logo-color-3' /%3e%3cpath d='M508,20.89H478.3v89.88H458.47V20.89H428.72V.94H508ZM338.71.94H315.29L293.47,39.56,271.66.94H248.24l32.15,54.87-32.15,54.88h23.42l21.81-38.63,21.82,38.63h23.42L306.56,55.81ZM557,19.93A22.11,22.11,0,0,0,535,42V69.6a22.09,22.09,0,0,0,44.17,0V42A22.11,22.11,0,0,0,557,19.93M557,0a42,42,0,0,1,42,42V69.6a42,42,0,0,1-42,42h0a42,42,0,0,1-42-42V42A42,42,0,0,1,557,0Zm-196,110.68H339.55L373.17.94h22.26l24.75,78.77H398.24L384.3,34.9ZM241.24.94H168v20h73.2Zm0,89.86H168v20h73.2Zm0-44.93H168v20h73.2Z' fill='%235f5c5c' class='logo-color-1' /%3e%3cpath d='M1004.88,15.77H975.29l0,94.67H960l.05-94.68H929.5V.43l75.37,0Zm-322.68.42H659.62l0,79.5h22.58a22.61,22.61,0,0,0,22.59-22.58l0-34.32a22.61,22.61,0,0,0-22.57-22.6m0-15.06A37.64,37.64,0,0,1,719.82,38.8V73.12a37.64,37.64,0,0,1-37.65,37.63l-37.64,0,.06-109.61Zm376.8,90-41,0-5.48,19.64H997.7l30.46-110h20.77l30.36,110h-14.82Zm-4.23-15.24-16.24-58.45-16.3,58.44ZM764.86.77H749.92l-.06,110.73h14.95ZM915,.77H900.09l-.05,110h15ZM1153.86,96l-44.61,0,0-94.85h-15.2l-.06,110,59.81,0ZM839.93,54.19v14.5h15.56v7.52a23,23,0,0,1-45.76-2.41V37.15A23,23,0,0,1,855,31.91h14.92a37.64,37.64,0,0,0-74.84,5.22l0,36.66a37.68,37.68,0,0,0,60.43,29.53V111h14.81l0-56.79Z' fill='%23b6bdb7' class='logo-color-2' /%3e%3c/g%3e%3cg class='brand-shield'%3e%3cpath d='M79.62,53.58V65.41H67V53.58Z' fill='%23fbc257' class='logo-color-3' /%3e%3cpath d='M79.62,29.93V41.76H28.38V29.93ZM28.38,53.58V65.41H60.7V53.58Zm0,23.65V89.06H79.62V77.23ZM108,.77v110L54,135.91,0,110.73V.77H108ZM96.18,103.2V12.59H11.82V103.2L54,122.86Z' fill='%235f5c5c' class='logo-color-1' /%3e%3c/g%3e%3c/svg%3e");
  height: 21px;
  margin-bottom: -6px;
}

.swagger-ui .topbar .download-url-wrapper input[type=text] {
  border: 1px solid #DEE2E6;
  margin-right: 16px;
  color: #757575;
  border-radius: 4px;
}

.swagger-ui .topbar .download-url-wrapper .download-url-button {
  background: #fcc52c;
  border-radius: 28px;
  color: #5f5c5c;
  font-size: 12px;
  text-transform: uppercase;
}

.swagger-ui .info {
  margin: 16px 0;
}

.swagger-ui .info hgroup.main {
  margin: 0;
  display: flex;
  align-items: center;
}

.swagger-ui .info .title {
  color: #7a7a79;
  font-size: 16px;
  margin-bottom: 0px;
  margin-right: 8px;
}

.swagger-ui .info .base-url {
  margin-right: 8px
}

.swagger-ui .info pre {
  font-size: 80%;
}

.swagger-ui .opblock-tag {
  padding: 6px 20px 6px 10px;
  color: #5f5c5c;
  font-size: 16px;
  border-bottom: 0;
}

.swagger-ui .opblock.opblock-get .opblock-summary-method {
  background: #2abab5;
}

.swagger-ui .opblock.opblock-get {
  background: rgba(42, 186, 181, .1);
  border-color: #2abab5;
}

.swagger-ui .opblock.opblock-get .opblock-summary {
  border-color: #2abab5;
}

.swagger-ui .opblock .opblock-summary {
  padding: 10px;
}

.swagger-ui .opblock .opblock-summary-operation-id,
.swagger-ui .opblock .opblock-summary-path,
.swagger-ui .opblock .opblock-summary-path__deprecated {
  color: #2abab5;
  font-size: 15px;
}

.swagger-ui .opblock .opblock-summary-description {
  color: #2abab5;
}

.swagger-ui .model-box-control:focus,
.swagger-ui .models-control:focus,
.swagger-ui .opblock-summary-control:focus {
  outline: none;
}

.swagger-ui .opblock-tag-section {
  margin-bottom: 16px;
}

.swagger-ui .expand-operation {
  padding: 0.863rem 1.8rem;
}

.swagger-ui .expand-methods svg,
.swagger-ui .expand-operation svg,
.arrow,
.btn svg {
  height: 16px;
  width: 16px;
}

.swagger-ui .scheme-container {
  padding: 1.5rem;
  background: #FBFBFB;
  box-shadow: none;
  border-radius: .25rem;
}

.swagger-ui .opblock .opblock-section-header {
  background: hsla(0, 0%, 100%, .7);
}

.authorization__btn svg {
  height: 16px;
  width: 16px;
}

.swagger-ui .scheme-container .schemes .schemes-title {
  display: none;
}

.swagger-ui .scheme-container .schemes > label select {
  min-width: 130px;
  text-transform: uppercase;
}

.swagger-ui .btn {
  border: 1px solid grey;
  border-radius: 28px;
  padding: 8px 23px;
}

.swagger-ui select {
  font-weight: 400;
  border: 1px solid #41444e;
  box-shadow: none;
}

.swagger-ui .btn.execute {
  background-color: #fcc52c;
  border-color: #fcc52c;
  color: #5f5c5c;
}


.swagger-ui input[type=email]:focus,
.swagger-ui input[type=file]:focus,
.swagger-ui input[type=password]:focus,
.swagger-ui input[type=search]:focus,
.swagger-ui input[type=text]:focus,
.swagger-ui textarea:focus {
  border: 1px solid #f2b303;
  outline: none;
}
