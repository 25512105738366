/* fade dos elementos em geral */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.25s ease-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

/*
    fade3
    =====
*/

.fade3-enter-active, .fade3-leave-active {
    transition: opacity .3s;
}

.fade3-enter, .fade3-leave-to {
    opacity: 0;
}

/*
    slide-fade-down
    ===============
*/

.slide-fade-down-enter-active {
    transition: all .1s ease;
}

.slide-fade-down-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-down-enter, .slide-fade-down-leave-to {
    transform: translateY(10px);
    opacity: 0;
}



.slideDown{
	animation-name: slideDown;
	animation-duration: .2s;
	animation-timing-function: ease-in;	
	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
        transform: translateY(-100%);
        opacity: 0;
	}		
	100% {
        transform: translateY(0%);
        opacity: 1;
	}		
}

/* fade-in-down */

.fade-in-down {
    opacity: 0;
    animation: fade-in-down .3s ease-in both;
}
@keyframes fade-in-down {

    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    
	100% {
        transform: translateY(0%);
        opacity: 1;
	}	


    /* from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    } */
}





/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fade-in {
  opacity:0;  /* make things invisible upon start */  
  animation:fadeIn ease-in 1;  
  animation-fill-mode:forwards;
  animation-duration:.3s;
}

.fadeIn {
  opacity: 0;
}
.fadeIn.animate-active  {    
  animation-name: fadeIn;    
}

///----------------------------------------------------

/* make keyframes that tell the start state and the end state of our object */
@keyframes fadeout { from { opacity:1; } to { opacity:0; } }

.fade-out {
  opacity:0;  /* make things invisible upon start */  
  animation:fadeout ease-in 1;
  animation-fill-mode:forwards;  
  animation-duration:.3s;
}

.zoomIn {
    animation-duration:.3s;    
    animation-name: zoomIn;
}

@keyframes zoomIn {
    from {
        opacity: 0;      
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes swing {
    20% {      
      transform: rotate3d(0, 0, 1, 15deg);
    }
  
    40% {      
      transform: rotate3d(0, 0, 1, -10deg);
    }
  
    60% {      
      transform: rotate3d(0, 0, 1, 5deg);
    }
  
    80% {      
      transform: rotate3d(0, 0, 1, -5deg);
    }
  
    to {      
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }
  
  .swing {    
    transform-origin: top center;    
    animation-name: swing;

    animation-duration:1s;
  }


  @keyframes tada {
    from {      
      transform: scale3d(1, 1, 1);
    }
  
    10%,
    20% {      
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
  
    30%,
    50%,
    70%,
    90% {      
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
  
    40%,
    60%,
    80% {      
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
  
    to {      
      transform: scale3d(1, 1, 1);
    }
  }
  
  .tada {    
    animation-name: tada;
    animation-duration:1s;
  }

  
  @keyframes fadeInUp {
    from {
      opacity: 0;      
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;      
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInUp {
    opacity: 0;
  }

  .fadeInUp.animate-active {    
    animation-name: fadeInUp;
  }


  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }  

  .fadeInLeft {
    opacity: 0;
  }
  .fadeInLeft.animate-active  {
    animation-name: fadeInLeft;
  }
  
  
  @keyframes fadeInRight {
    from {
      opacity: 0;      
      transform: translate3d(100%, 0, 0);
    }
    
    to {
      opacity: 1;      
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    opacity: 0;
  }  
  .fadeInRight.animate-active  {
    animation-name: fadeInRight;
  }
 


  // Classe adicionada como o uso do v-animate
  .animate {    
    animation-duration: 1s;    
    animation-fill-mode: both;
  }

  .animate.delay-100ms {    
    animation-delay: .1s;
  }

  .animate.delay-150ms {    
    animation-delay: .15s;
  }

  .animate.delay-200ms {    
    animation-delay: .2s;
  }

  .animate.delay-250ms {    
    animation-delay: .25s;
  }

  .animate.delay-300ms {    
    animation-delay: .3s;
  }
  
  .animate.delay-500ms {    
    animation-delay: .5s;
  }
  
  .animate.delay-750ms {    
    animation-delay: .75s;
  }

  .animate.fast {   
    animation-duration: 800ms;
  }
  .animate.faster {   
    animation-duration: 500ms;
  }

/*  spinner */
  .rotate {
    animation: spin 0.7s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

// deveria ser genérico
.slide-enter-active {
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.1s;
  //transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0.45, 0.25, 0.6, 0.95);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

