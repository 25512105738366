@import "_sizes.less";
@import "../../abstracts/_colors.less";
//@import "_animations.less"; // Desabilitado, pois já é importado no _exato-web.less.
@import "../../abstracts/_screen-size.less";

/* novo menu mobile */

// .nav-item {
//   position: relative;
//   color: @ColorPriGrey;
// }

.nav-item-principal {
  @media (min-width: @screenLarge) {
    padding-left: 1.5rem;
  }
}

.nav-link-login {
  margin-right: 0;
}

.nav-link-subitem {
  opacity: 0.5;

  @media (min-width: @screenLarge) {
    opacity: 0.8;
  }
}

//TODO - Avaliar: parece que esse trecho não é mais necessário
// .button-box {
//   // padding: 0 2rem;
//   flex-basis: 100%;

//   @media (min-width: @screenLarge) {
//     padding: 0;
//     //margin-left: 2rem;
//   }

//   a {
//     display: inline-block;

//     @media (max-width: @screenMedium) {
//       width: 100%;
//       max-width: 290px;
//       margin: auto !important;
//     }
//     @media (max-width: @screenSmall) {
//       max-width: 100%;;
//     }

//   }
// }

@media (min-width: @screenLarge) {
  .nav-item-separator {
    padding-left: 2rem;
    border-left-width: 1px;
  }
}

//.landing-unified {
// .nav-item {
//   position: relative;
//   color: @ColorPriGrey;

//   // @media (min-width: @screenLarge) {
//   //   display: flex;
//   //   align-items: center;
//   // }
// }
//}


/* end novo mobile */
//.userAuthenticated {
// @media (min-width: @screenLarge) {
//   .nav-item {
//     display: flex;
//     align-items: center;
//     color: @ColorPriGrey;
//   }
// }
//}


.btn-primary-outline {
  border: 1px solid #d7d6cf;
}
