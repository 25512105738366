//@import "_global.less"; // Desabilitado, pois já é importado no _exato-web.less.
@import "_sizes.less";
//@import "styles/bootstrap/bootstrap-grid-responsive.less"; // Desabilitado, pois aqui já tem Bootstrap.

#app {
  display: flex;
  flex-direction: column;
  height: inherit;

  //AVALIAR SE VAI QUEBRAR O SITE EM ALGUM LOCAL
  // - Está quebrando resoluções menores que 1320px
  //min-width: @PageWidth + 40px;

  main {
    flex: 1 0 auto;
  }

  >*:last-child {
    flex-shrink: 0;
  }
}

.pagina-enter-active,
.pagina-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}
.pagina-enter,
.pagina-leave-active {
  opacity: 0;
  transition-timing-function: ease-in;
}

@media print {
  #app {
    min-width: auto;
  }
}
