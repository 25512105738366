//
// As animações fade-in-up-10px e fade-out-down-10px são apenas um port das
// classes que compõem a animação slide-fade-down do Exato Web aplicada via Vue.
// Elas se encontram no arquivo vendors/exato-web/_animations.less.
//

//
// fade-in-up-10px
//

@keyframes fade-in-up-10px {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-up-10px {
  animation: fade-in-up-10px .1s ease;
}

//
// fade-out-down-10px
//

@keyframes fade-out-down-10px {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

.fade-out-down-10px {
  animation: fade-out-down-10px .1s ease;
}
