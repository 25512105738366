@import "../../abstracts/_colors.less";
@import "../../abstracts/_screen-size.less";

.nav-item {
  position: relative;
  color: @ColorPriGrey;

  @media (min-width: @screenLarge) {
    display: flex;
    align-items: center;
  }
}


// ordem do itens comuns do menu
.nav-item-corporate {
  order: 1;
}
.nav-item-resources {
  order: 2;
}
.nav-item-login {
  order: 3;
}
.nav-item-query {
  order: 4;
}
.nav-item-history {
  order: 5;
}
.nav-item-balance {
  order: 6;
}
@media (max-width: @screenMedium) {
  .nav-item-corporate {
    order: 2;
  }
  .nav-item-resources {
    order: 3;
  }
  .nav-item-login {
    order: 4;
  }
  .nav-item-query {
    order: 5;
  }
  .nav-item-buy {
    order: 6;
  }
  .nav-item-logout {
    order: 7;
  }
}

// caso não autenticado, define ordem com itens da landing
.header-flashcheck {

  .nav-item-try {
    order: 3;
  }
  .nav-item-revenue-distribution {
    order: 4;
  }
  .nav-item-login {
    order: 5;
  }

  @media (max-width: @screenMedium) {
    .nav-item-try {
      order: 4;
    }
    .nav-item-revenue-distribution {
      order: 5;
    }
    .nav-item-login {
      order: 6;
    }
  }
}

// caso não autenticado, define ordem com itens da landing
.header-executivecheck {

  .nav-item-our-solution {
    order: 3;
  }
  .nav-item-how-work {
    order: 4;
  }
  .nav-item-login {
    order: 5;
  }

  @media (max-width: @screenMedium) {
    .nav-item-our-solution {
      order: 4;
    }
    .nav-item-how-work {
      order: 5;
    }
    .nav-item-login {
      order: 6;
    }
  }
}


// PADRÃO PARA TODOS OS MENUS
.nav-item-button {
  margin: 1rem 0;
  order: 29; // botão sempre por último no mobile
}
.nav-link-user {
  order: 30;
}
.nav-item-locale {
  order: 31;
}

@media (max-width: @screenMedium) {

  .nav-item-locale {
    order: 1; // sempre em primeiro no mobile
  }
  .nav-item-button {
    margin: 1rem 0;
    order: 31; // botão sempre por último no mobile
  }
}

//  fim ordem dos menus

.nav-item-principal {
  @media (min-width: @screenLarge) {
    padding-left: 1.5rem;
  }
}

@media (min-width: @screenLarge) {
  .navbar-expand-lg .navbar-nav {
    margin-left: auto;
    flex-direction: row;
  }
}

.nav-link {
  position: relative;
  display: block;
  padding: 0.75rem 0rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: inherit;
  text-transform: uppercase;
  //para alinhar os links que não tem ícon com os links que tem icone
  line-height: 24.5px;

  opacity: 0.9;

  @media (min-width: @screenLarge) {
    font-size: 0.75rem;
    padding: 1.422rem 1.5rem;
    &:hover {
      color: @yellow-600;

      .dropdown-caret {
        &:before {
          border-color: @yellow-600;
        }
      }

    }
  }
}

.nav-link.menu-selected {
  color: @yellow-600;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: @yellow-600;
  }

  .dropdown-caret {
    &:before {
      border-color: @yellow-600;
    }
  }
}

.nav-link.menu-open {
  .dropdown-indicator {
    transform: rotate(180deg);
  }
}

.nav-link-dropdown {
  display: flex;
  transition: .0s all;
}

.nav-link-icon {
  position: relative;
  top: 0.2em;
}

@media (min-width: @screenLarge) {
  .nav-link-separator-left {
    // border-left: 1px solid #e3e5e5;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 1px;
      height: 20px;
      border-left: 1px solid @light300;
    }

  }
  .nav-link-separator-right {
    // border-right: 1px solid #e3e5e5;
    &::before {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 20px;
      border-left: 1px solid @light300;
    }
  }

  .nav-link-icon {

    .icon-with-text {
      margin-right: 0;
    }
  }
}

@media (max-width: @screenMedium) {
  .nav-link-md-separator-top {
    border-top: 1px solid #e3e5e5;
  }
}
