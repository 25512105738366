//
// Document
//

html {
  box-sizing: border-box;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  scroll-behavior: smooth;
}


//
// Links
//

a {
  transition: all .3s;
}
