@import "../../abstracts/_colors.less";
@import "../../abstracts/_screen-size.less";

.brand {
  display: block;
  width: 169.79px;
  height: 20px;
  margin-top: 0.25rem;
  transition: 0.3s all;

  @media (min-width: @screenLarge) {
    width: 155px;
    height: 18px;
  }


}

.logo-white {
  .logo-color-1,
  .logo-color-2,
  .logo-color-3 {
    fill: #fff;
  }
}
.logo-black-exato {
  .logo-color-1,
  .logo-color-2,
  .logo-color-3 {
    fill: @ColorPriGrey;
  }
}
