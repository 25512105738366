@import "../abstracts/_colors";

//
// Importando tudo dentro de uma classe para não afetar outros estilos da
// documentação. Dessa forma, seu elemento proveniente do Exato Web precisa
// estar dentro de outro que contenha a classe abaixo para os estilos
// funcionarem.
//
.exato-web {
  //
  // Copiado do seletor "body" do arquivo "_global.less" já que esses estilos
  // originalmente nunca seriam aplicados e também inclui as fontes do Bootstrap
  // como fallback.
  //
  font-family: "Saira", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 1.5;
  color: @ColorPriGrey;
  background-color: @ColorSecGrey03;
  height: inherit;
  
  @import "exato-web/_animations";
  @import "exato-web/_global";
  @import "exato-web/_App.vue.less";
  @import "exato-web/_header.vue.less";
  @import "exato-web/_header-exato.vue.less";
  @import "exato-web/_button.vue.less";
  @import "exato-web/_nav-link-horizontal-container.vue.less";
  @import "exato-web/_nav-link-horizontal.vue.less";
  @import "exato-web/_logo-exato.vue.less";
}
