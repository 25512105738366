//
// Sobrescreve "bootstrap/scss/reboot"
//

body {
  background-color: #f5f7f7;
}

a {
  color: #2abab5;

  &:hover {
    color: #2abab5;
  }
}


//
// Sobrescreve "bootstrap/scss/type"
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 700;
}


//
// Sobrescreve "bootstrap/scss/code"
//

pre,
code,
kbd,
samp {
  color: inherit;
}


//
// Sobrescreve "bootstrap/scss/navbar"
//

//
// Isso aqui removia o padding dos itens do novo menu importado do Exato Web.
//
//.navbar-expand-lg .navbar-nav .nav-link {
//  @media (min-width: 992px) {
//    padding-right: 0;
//    padding-left: 0;
//  }
//}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #5f5c5c;
}


//
// Sobrescreve "bootstrap/scss/buttons"
//

.btn {
  position: relative;
  display: inline-block;
  padding: 0.735rem 1.8rem;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border-style: none;
  border-radius: 28px;
  cursor: pointer;
  transition: all 0.3s;
}

.btn-primary {
  background-color: #fcc52c;
  color: #4a4a4a;
}

.btn-primary:not(:disabled):not(.disabled):hover,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle {
  background-color: #c99503;
  color: #4a4a4a;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-link {
  width: auto !important;
  padding: 0.863rem 1rem;
  color: #2abab5;
}

.btn-link:hover:enabled,
.btn-link:focus:enabled,
.btn-link:focus:active {
  color: #fcc52c;
  text-decoration: none;
  box-shadow: none;
}


//
// Sobrescreve "bootstrap/scss/modal"
//

.modal-dialog {
  @media (min-width: 576px) {
    max-width: 615px;
  }
}

.modal-title {
  font-weight: 500;
}

.modal-content {
  border: 0;
  border-radius: 0.125rem;
}

.modal-header {
  padding: 1.25rem 1.5rem .625rem 1.5rem;
  border-bottom: 0;
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem
}

.modal-body {
  padding: 0 1.5rem 1.25rem 1.5rem;
}

.modal-footer {
  padding: 0 1.5rem 1.25rem 1.5rem;
  border-top: 0;
}
