@import "../../abstracts/_screen-size.less";

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding: 1rem 1rem 0 1.5rem;
  margin-bottom: 1.5rem;
  list-style: none;

  @media (min-width: @screenLarge) {
    flex-direction: row;
    padding: 0;
    margin: 0;
    margin-left: auto;
  }
}

//TODO testar no executive
@media (min-width: @screenLarge) {
  .navbar-expand-lg .navbar-nav {
    margin-left: auto;
    flex-direction: row;
  }
}
