@import "../../abstracts/_colors.less";
@import "_fonts.less";
@import "../../abstracts/_screen-size.less";

/* Tratar status dos botões:  hover, focus, active, disable  */

button {
  position: relative;
  display: inline-block; // checar se irá gerar algum problema
  padding: 0.863rem 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
  font-size: 15px;
  border-style: none;
  border-radius: 28px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s;

  @media (min-width: @screenLarge) {
    //padding: .969rem 1.8rem;
  }

  @media (max-width: @screenMedium) {
    width: 100%;
    max-width: 290px;
    margin: auto;
  }
  @media (max-width: @screenSmall) {
    max-width: 100%;;
  }
}

button:disabled {
  cursor: not-allowed;
  color: lighten(@ColorSecGrey47, 7%);
  background-color: lighten(@ColorSecGrey24, 12%) !important;
}

/* button:hover:enabled {
  background-color: darken(@ColorCorporatePrimary, 8%);
} */

.btn-primary {
  background-color: @ColorPriYellow;
  color: @ColorSecGrey74;

  &:hover:enabled, &:focus:enabled {
    background-color: darken(@ColorPriYellow, 18%);
  }
}

.btn-secondary {
  background-color: @ColorPriTurquoise;
  color: @ColorPriWhite;
  border: 1px solid transparent;

  &:hover:enabled:not(.active), &:focus:enabled:not(.active) {
    background-color: @turquesa-700 !important;
  }
}

.btn-tertiary {
  background-color: @ColorCorporatePrimary;
  color: @ColorPriWhite;

  &:hover:enabled, &:focus:enabled {
    background-color: @ColorPriYellow;
    color: @ColorSecGrey74;
  }
}


.btn-link, .btn-link-light {
  width: auto !important;
  padding: 0.863rem 1rem;
  color: @ColorPriTurquoise;

  &:hover:enabled, &:focus:enabled {
    color: @ColorPriYellow;
  }

  &:disabled {
    color: #918F8F;
    background-color: transparent !important;
  }
}
.btn-link-light {
  color: #918F8F;
}



.btn-small {
  padding: 0.788rem 1.8rem;
  font-size: 14px;
}

//este botão deveria ser o small
.btn-micro {
  padding: 0.5rem 1.5rem;
  font-size: 12px;
}

.btn-primary-outline {
  border: 1px solid #707070;
  color: #4A4A4A;

  &:hover:enabled, &:focus:enabled {
    background-color: darken(@ColorPriYellow, 18%);
    border: 1px solid darken(@ColorPriYellow, 18%);
  }
}

.btn-outline-dark {
  border: 1px solid @ColorSecGrey74;
  color: @ColorSecGrey74;

  &:hover:enabled, &:focus:enabled {
    background-color: @ColorSecGrey74;
    color: #FFF;
  }
}

.btn-outline-light {
  border: 1px solid @ColorSecGrey24;
  color: @ColorSecGrey24;

  &:hover:enabled, &:focus:enabled {
    border: 1px solid @ColorSecGrey63;
    background-color: @ColorSecGrey63;
    color: #FFF;
  }
}

.btn-secondary-outline {
  border: 1px solid @ColorPriTurquoise;
  color: @ColorPriTurquoise;

  &:hover:enabled, &:focus:enabled {
    background-color: @ColorPriTurquoise;
    color: @ColorPriWhite;
  }

  &:disabled {
    background-color: transparent !important;
    border: 1px solid lighten(@ColorSecGrey24, 12%);
  }
}

.btn-tertiary-outline {
  border: 1px solid @ColorCorporatePrimary;
}


.btn-light-outline {
  background-color: #fbfbfb;
  color: #757575;
  border: 1px solid #dee2e6;
}

.btn-square {
  border-radius: 4px;
}

.active {
  cursor: auto;

  /* &:hover {
    background-color: currentcolor;
  } */
}
